import React from "react"
import {
    ReactVideoPlayer,
    ReactAudioPlayer
} from "../components/reactPlayer"

const fixture = [
    
    {
        quote: `<p>Hi Karina,<br />
        <p>Thank you for checking in. I was just thinking of writing to you. After the session I had my ENT appointments.  The ENT doctor was stunned and didn't have any reason why I showed sudden hearing loss at the urgent care the previous night but the audiology report was not showing the hearing loss.</p> <p>He told me to not take any steroids and said, “I'm really happy for you. I cannot explain what happened and why but I wish you a great day.” </p><p>My right ear hearing has continued to be fine. I slept peacefully through the night. I don't have any feeling of my right ear being blocked. Per your advice, I did Permissiong this morning as well and will repeat it tonight.</p><p>I think I experienced a miracle? Sudden hearing loss and couldn't hear anything to hearing tests being normal in a span of 24hrs. Some QT at night, some QT in the morning with toning, per your advice, and a mind-blowing healing session from you with your spectacular energy and love.</p><p>I'm eternally grateful to you and thank the universe everyday for connecting me with you. Thank you from my heart!</p><p>Lots of love and gratitude,</p>`,
        citationName: `Sarah, USA`
    },
    
    {
        quote: `<p>I wanted to let you know that Rachel has not had stomachache or needed gas/bloating medication since the healing session. Her appetite went up as well and after a month or so I saw her eat normally! </p>
        <p>She also seems calmer - she hadn’t studied for her tests on Friday as she wasn’t feeling well before then. </p>
        <p>Normally she would be really worried but on the way to school she said, "I'm fine. I'll just do my best." Mind boggling. </p>
        <p>You don’t cease to amaze me Karina. </p>
        <p>The universe has sent you in our lives and what spectacular karma you will have for helping those around you.</p>`,
        citationName: `S.S, USA`
    },
    {
        quote: `<p>The session I had with Karina was the catalyst to find a way to help move me out of an enormous trauma. Permissioning™ is the most powerful thing I have ever done and Karina personalised it for my situation.</p>`,
        citationName: `L. Goodman, Producer of “Shit to Sunshine” podcast, UK`

    },
    {
        quote: `<p>I had a very unpleasant and big bike accident a few weeks ago when my bike flipped over whilst cycling. I had been seriously struggling with a lot of health problems since then. My lungs felt as if someone was sitting on them with a tight chest, short breaths and a lot of anxiety. My vocal chords had a tear, pelvic bones were misaligned and very painful as was my neck and spine. I described this to Karina as level 8 or 9 pain.</p><p>Within the one hour session every single one of my issues resolved. Karina's energy is just miraculous to me and I highly recommend her. She is a very strong practitioner with tangible and definite results. I have only positive superlatives to describe how grateful I am for the healing and how I feel now. </p>`,
        citationName: `Z.M, Slovenia`
    },
    {
        quote: `<p>I have a therapy subscription service that I can call through my company. It is part of the wellbeing provision. The calls have been helpful and it was beneficial to talk. However, after each session with Karina I come away with life changing insights and tools. It is on another level to anything I have experienced therapeutically. I can literally pinpoint huge changes in my life happening after each session with you. I don't have enough words to express my gratitude.</p>`,
        citationName: `D.J, UK`
    },
    {
        quote: `<p>The past months had been a very stressful period for me and as soon as things were starting to ease slightly, I happened to stretch my arm out and injure my neck and shoulder in the process.</p>

        <p>It didn’t feel too bad but by the next day it was a lot worse and I was in quite a bit of pain when having to shift sleeping positions during the night. I also had very limited range of movement in my neck which made daily tasks difficult, in addition to the pain.</p>
        
        <p>Karina and I had a few sessions and even after the first, I was already able to sleep much more peacefully, with freedom to rotate positions in bed whilst sleeping and some flexibility had returned! The two following sessions only added to the healing and I’m so grateful to have come away feeling so calm afterwards too! What a blessing! No pain and full movement and flexibility have returned!</p>`,
        citationName: `Joanna Gregory, London`
    },
    {
        quote: `<p>I’ve had several mind blowing remote QT healing sessions with Karina, for a long standing chronic endocrine illness that had not responded well to standard medical care. With each session Karina has powerfully shifted my energy to a frequency that is enabling healing from an emotional root cause level.</p><p>I can whole heartedly testify that Karina is an exceptional healer. In awe of the QT sessions received, I was inspired to enrol on many of Karina’s courses, all of which have been completely amazing and enriched my life in so many wonderful ways. Also having previously struggled to be able to meditate for anything longer than a couple of minutes, since I started to use Karina’s meditations from her website, I have finally been able to with ease and joy! I use them on a daily basis and the benefits are powerful.</p><p>I am sleeping so much better, my outlook on life is enriched and I’m more relaxed than I could ever believe possible. Thank you so much Karina for all that you do.</p>`,
        citationName: `Dr. Jo Pelho, Scientist UK`
    },
    {
        quote: `<p>I am always in awe of Karina when I hear her speak. The way she presents and interacts with the audience is so flawless. I always breathe a sigh of relief when I am interviewing Karina because I know she will be very very well prepared. </p><p>Not only are her presentations well done, the healing work she does with live callers is some of the best I've witnessed. </p><p>She gets results fast and makes sure to work with the caller until they experience some kind of result, which is typically within a few minutes. I highly give my recommendation for Karina as both a speaker and a healer.</p>`,
        citationName: `Gretchen Pritts, Co Host for Bliss up and FHTJ`
    },
    {
        quote: `<p>I originally contacted Karina in November, 2021. A car accident 34 years earlier had shifted my pelvis, causing me to limp and have constant pain on one side of my body. No therapist had been able to help me.</p>
<p>Karina was lovely. She didn’t ask many questions. She only asked where the pain was, and then started the session. As she worked, I came to realise that my problems were not due to my accident, but to past emotional traumas. In six Quantum Touch sessions over the following 2 1/2 months these traumas released, one by one, and as this happened, my spine and my leg muscles began to operate better.</p>
<p>Karina’s work is powerful. My 81 year-old body was shocked. I took Bach flower remedies to help it assimilate the changes.</p>
<p>The result? I now use my leg and back muscles appropriately. My spine is straight, my limp has disappeared and my pain and stiffness are much reduced.</p>
<p>In a few months Karina has done what no other therapist, in the US or the UK, has been able to do in 34 years.Thank you, Karina!</p>`,
        citationName: `Thalassa Scholl, Writer UK`
    },
    {
        quote: `<p>Following a period of intense stress my husband was prescribed a blood pressure medication, he stopped the meds after a few days due to adverse reactions including excruciating muscle pains, spasms and an elevated need for urination and fatigue, immediately on stopping the medication the pain and urination problem stopped but he was left with such fatigue he couldn't get out of bed and developed constant twitching in his muscles.</p>
<p>He was admitted to hospital and diagnosed with atrial fibrillation (we can only assume caused by the meds as he does not have a heart condition) and prescribed more meds which he was told could be a lifelong prescription.</p>
<p>Once home I arranged for some QT sessions with Karina and the symptoms started reducing, the following week he had another ECG scan which was normal and he was told he no longer needed the medication. He feels like his old self and his blood pressure (which has always been bordering on the high side) is now within the normal range. QT healing is remarkable, thank you Karina!</p>`,
        citationName: `Sue Bryant, Bristol`
    },
    {
        quote: `<p>I forgot to mention that I've had a lot of compliments about how I look since I started your healing program, from people I haven't seen for a while.  My husband is also looking better and his walking has improved. He also seems happier. We are continuing to listen to the most relevant audios.</p>`,
        citationName: `S.Y, USA`
    },
    {
        quote: `<p>I attended Karina’s Level 1 & 2 Quantum-Touch workshops and had and still have private QT sessions with Karina. Karina is very professional, respectful, beautiful inside out, and knowledgeable.</p>
        <p>She helped me for around one year to overcome my chronic Lyme disease and low energy levels. After the first few sessions, I was able to have more energy for my kids and felt so much better physically and mentally.</p>
        <p>This positive result was also shown in my blood results as my Lyme disease has vanished.</p>       
        <p>I highly recommend getting in touch with this honest and radiating positive healing practitioner.</p>
        <p>Karina, thanks so much, for an amazing and delightful journey of my healing experience.</p>`,
        citationName: `Kate P, London`
    },
    {
        quote: `<p>During a very low moment I typed 'healing' in google and Karina''s website showed up. I had never heard of QT, nor had I tried any sort of energy work before but as I started reading about Karina, I felt I had to reach out.</p><p> I initially wanted Karina's help with a pinched nerve in my neck/shoulder that used to cause my hand to feel like it's on fire and I had a lot of tingling in my fingers. I have had 6 healing sessions with Karina over the phone and each one of them has been nothing short of mind-boggling. After a few sessions I started seeing remarkable improvement in my hands.</p><p> Now I feel the tingling only occasionally and when it happens, I remind myself of Karina's words... if the pain can go down to 1, it can go down to zero and eventually over the next few hours it just disappears.</p><p> Not only that, Karina has cured a herniated disc in my lower back that has troubled me for the last 13 years. I keep expecting the familiar pain to come back, but nothing. I had a colitis flare up and after just two sessions, all my lab test results came back normal, showing no inflammation at all! Pain in my hip - cured. I had a nickel allergy that had been bothering me for six months. It just cleared up on its own. It's like my body is learning to get back to balance, all thanks to Karina!</p><p>Karina is just the gentlest, kindest soul and she has so much love and empathy in her heart. I truly believe that she is a blessing that the universe sent my way. I have felt incredible joy and stillness/calm during the healing sessions that I cannot really describe. Her words of encouragement ring in my ears each time I start feeling low. I have now started learning Permissioning from Karina to continue my healing journey. With all my heart, I thank you Karina.</p>`,
        citationName: `Shiv, California`
    },
    {
        quote: `<p>I am so so grateful that you did the healing for Charlie. He played very well, his foot felt great and he hasn't experienced any more pain. Thank you so so much!!</p>`,
        citationName: `S Toubian - Mother of Academy Football player`
    },
    {
        quote: `<p>Karina is a wonderful healing practitioner and if you have the chance to have a session with her, I highly recommend it. The energy is palpable, relaxing and amazing!</p>
<p>She is also the best teacher I have ever come across in all my years of mindfulness meditation and healing.</p>`,
        citationName: `Tyroon Win - Practice Manager at Partnership Counsel`
    },
    {
        quote: `<p>I have suffered from severe period pains since the birth of my daughter, who is now 4 years old. In the beginning I would have them for a few hours, but they progressively got worse with each period. In the end I had pains and discomfort, so that usual activities such as running with my daughter were no longer possible. After one session with Karina they have disappeared completely and haven’t returned. I am so thankful to her as they were quite debilitating at times.</p>`,
        citationName: `J.E, England`
    },
    {
        quote: `<p>I came back to seek Karina’s help again due to ongoing pain on my lower back. I have a bulge on my second lowest disc and this has been giving me a lot of problems lately. I was in pain every day and my back was so weak that it felt like it was going to go at any time.</p>
        <p>Karina had successfully helped me eight years ago with my bunions so I decided to ask for her help again.</p>
        <p>I had four sessions with Karina this time round to help me with my current problem. I am now totally pain-free and my back feels much stronger!</p>
        <p>Thanks to the healing, I can now do mundane things that I couldn’t do before without pain.</p>
        <p>I highly recommend Karina as she is incredibly talented with Quantum-Touch and she has once again given me the healing needed to be pain-free!!</p>
        <p>Thank you Karina so much!!!</p>`,
        citationName: `Marina Maccormack, Teaching Assistant England`
    },
    {
        quote: `<p>Over 3 years ago I had constant problems with my ankles and feet. Finally, it got to a level where I could hardly walk anymore.</p>
    <p>I found out about Karina and her Quantum-Touch modality of healing on the internet. After starting my healing sessions with Karina, my healing and improvements happened quickly. The pain and discomfort in my ankles and feet went away, and my whole body felt so much more flexible. What a wonderful feeling it was! I had not felt like this for ages.</p>
    <p>After 3 or 4 sessions with Karina, my problems simply vanished. It has been one year since our sessions and there is still no discomfort in my feet. This is so amazing! I was really lucky to find Karina and her excellent healing techniques. She can really work miracles!</p>`,
        citationName: `Soili, Helsinki, Finland`
    },
    {
        quote: `<p>Dear Karina,<br />
        You asked me to update you on how I’ve been following our session a couple of weeks ago.</p>
        <p>Well, it’s been quite amazing! My lower back pain, and much of the muscle tightness around that area and in my buttocks has almost completely gone. I am no longer wearing my back brace and feel so much stronger and  more flexible in that area.</p>
        <p>Thank you and best wishes,</p>`,
        citationName: `S.D`
    },
    {
        quote: `<p>I attended Karina’s Level 1 workshop in 2019. I had no idea what this workshop would do for me. I had some pain in my hips and on the first day Karina gave me just a few minutes of Quantum-Touch and to my astonishment, within just a few minutes my pain had gone! Some people may or may not believe that this has happened, but it did!</p>
<p>I am learning the second course now and would recommend Karina’s workshops.</p>
<p>She has a very relaxed and respectful attitude towards all her participants and she is always smiling.</p>
<p>Many thanks, Karina”<cite>R.P GP Practice Manager, London`
    },
    {
        quote: `<p>Thank you Karina for the healing session. During the session, my pain in the left elbow and left finger came down from 6 to 2 and my feet from 8 to 2. You also worked on my throat. Again the discomfort was 6/7 and after the healing, it was completely fine.</p>
    <p>After a couple of days, the pain from my elbow and finger has almost gone. My feet are also much better!</p>
    <p>Thank you again Karina for the wonderful healing session.</p>`,
        citationName: `Yasmin Z, NHS Nurse`
    },
    {
        quote: `<p>After attending Karina’s festive group healing session</p>
        <p>A warm heartfelt thank you for the healing session the 12th of December</p>
        <p>The energy was beautiful and almost palpable.</p>
        <p>I've had issues with an ankle after an injury many years ago (~50), and some years after had an accident with one knee that lead to problems with the other knee too. Sometimes I don't notice them, and sometimes they cause a lot of pain.</p>
        <p>When it hurts I would say it's somewhere between 3 and 9 depending on what I've been doing.</p>
        <p>After the session I haven't felt any pain or resistance at all in the knee that was injured, and it's the worst one.</p>`,
        citationName: `A.C, Sweden`
    },
    {
        quote: `<p>A few months after working through the SCH process for my panic attacks, I started to have mild dizziness symptoms. I went to a doctor and they told me that it was connected with a virus and that I shouldn’t move my head in the direction that made the dizziness worse. While I was at the Doctor’s he asked me about my migraines, how often they occurred and how bad they were. I described the symptoms and how often they happened and he responded by saying that they weren’t that bad and that there was no need to investigate them. So I left there feeling that there was no solution and I just had to put up with both my dizziness and migraines.</p>
<p>The dizziness got better a couple of weeks later, but 6 months after this I had an episode that lasted for 2 days and was so bad that I could barely stand and couldn’t walk without holding onto things. Even lying down I felt like the room was spinning around my head.</p>
<p>As the doctor had been unable to help previously, I didn’t see the point of going back, but instead contacted Karina, as I felt the symptoms maybe emotionally related. Karina worked through the SCH process with me again, and she very quickly helped me to get to the root of the symptoms, which was made perfect sense to me. She asked me while I was there whether I’d like to get to the root of my migraines, and I agreed straight away.</p>
<p>In complete contrast to the doctor who made me feel like there was no solution for my symptoms, Karina said she felt I could stop my migraines from happening and made me feel it was possible to resolve the symptoms completely, which was a huge relief!</p>
<p>Since resolving the underlying emotions related to my dizziness I haven’t had any further episodes. My migraines are also much less frequent, and I believe that once the emotions causing them are completely resolved that they will stop altogether. While I was working through the emotions associated with my symptoms, my friend did some QT healing sessions with me, which also really helped.</p>
<p>I think that one of the great things about the SCH process is the possibility of being able to get to the root cause of symptoms quickly without the need to talk about details of personal issues.</p>
<p>I also feel now that using the SCH process has enabled me to resolve emotions which would have otherwise remained suppressed.</p>`,
        citationName: `Tracy L, Veterinary nurse`
    },
    {
        quote: `<p>I have known Karina for about 5 years now and have had many sessions of Quantum-Touch from her. She has always been very professional and the energy work has been very useful to me both physically and emotionally. I would highly recommend her.</p>`,
        citationName: `Dr Graham Crook, M.B. Ch.B., MRCP, Retired Chest Physician`
    },
    {
        quote: `<p>Quantum-Touch is reported to be able to move bones that are out of alignment in the body, and it was truly amazing to feel this happen in the sessions with Karina. Very clear physical changes occurred in my body in just a few sessions.</p>
<p>While the scientific and clinical investigations of energy medicine such as QT are ongoing and are gaining momentum, the results with QT from a practical perspective are undeniable and outstanding. Like many other patients have reported, I found that QT achieves very real physical improvement, and that QT can easily facilitate return to health also for a condition considered very challenging for conventional medicine. The QT treatment was very powerful, and really facilitated return to complete health for me. Also,</p>
<p>I found Karina to be an excellent, skilful and compassionate practitioner.</p>`,
        citationName: `C.F, London PhD Scientist`
    },
    {
        quote: `<p>Hi Tracy,</p>
        <p>It is deep joy and gratitude that I write this having experienced first-hand the amazing energy in Karina's QAH healing program.  The energy was nothing short of amazing and the 4-week program has gone beyond in meeting all of my expectations.</p>

<p>I joined the program to learn how to become an energy healer to help alleviate the various illnesses and pain of both my family and friends.  I was more than elated to learn that the healing protocols can also be directed to myself.  After suffering migraine pain for more than 8 years, I can happily report that over the past two weeks I have had NO head pain.  This is the first time in many years that I have gone so many consecutive days without pain AND, without the need for my pain relief medication!  This has been truly amazing.</p>

<p>Additionally, I had forgotten to mention to Karina on the last call that when I started the program I had a toothache, which my dentist had told me I needed a root canal.  I wanted the tooth to heal on its own and I can again say that I now have NO pain!!</p>

<p>I'm so looking forward to furthering my expansion of the healing energy as I continue on the QAH journey over the next four weeks.  I'm so grateful for the loving energy that Karina has brought into my life.  Thank you, Karina for sharing your beautiful QAH program with us.  I can't tell you how overjoyed I am to have been able to bask in your healing energy and that I get to continue to do this for myself daily is such a gift.
</p>`,
        citationName: `Jean ❤️`
    },
    {
        quote: `<p>For many years I have had constant stress and worry over my son's well being. I also have been feeling stress and worry regarding my financial situation. I have meditated and done the daily energy routine which are great. </p> 
            <p>However after these 4 weeks of Karina's QAH course I feel amazing. A few days ago I decided to listen to Karina's replay before going to work.</p> 
            <p>I was tired of waking up stressed. I was also working with someone who was a bit toxic. Once I listened to the replay I felt so at peace, relaxed and happy. My whole day was different.</p> 
            <p>After so many years of worry and stress, it is amazing how I am out of the chit chat mind and am now into a deep state. </p> 
            <p>I do not have the worry thoughts. Instead I feel calm, peaceful, confident and hopeful. For the first time in many years I am excited, confident and hopeful about my future. Thank you Karina.</p>`,
        citationName: `June`
    },
    {
        quote: `<p>Karina had an interview at the Life Reawakened tele summit a couple of days ago and I asked her for a process to help release trauma - and 😱. Honestly - nothing has ever made me feel so much release ever over the last nearly 20 years of me trying and buying and investing time in different modalities and techniques. I am mindblown, for a lack of a better word. Sensations of heat in different areas - more calmness and…peace.</p><p>I've had such a trauma filled life and nothing has had as strong effect before than the little 4 minute process she did to help starting to heal that. I have listened to my replay and each time I get the reactions of release again and again in my body.</p><p>Please pass my gratitude also to Karina - just wow wow wow - so powerful. Thank you</p>`,
        citationName: `Marion`
    },
    {
        quote: `<p>Having suffered from chronic back problem for almost 5 years and having had constant physiotherapy and other alternative treatments, my first experience with Karina and QT blew me away. I immediately felt as if an energy was healing the exact points that needed healing in my back which no physiotherapist has ever come close to doing. I could physically feel my muscles untwisting and bones moving back into place that I never knew where out of place. I felt energised and the difference in my posture at the end of the session was incredible.</p>
<p>I look forward to more sessions and to being able to stand up straight with out pain!</p>`,
        citationName: `Jo Keen, London Fashion`
    },
    {
        quote: `<p>I am having QT sessions while I am on chemotherapy. One of the side effects was intense headaches with only two sessions with Karina my headaches were gone. ❤️‍</p>
<p>The QT sessions with Karina also helped my body to speed up my healing and get get great results on my treatment in such a short time 😊</p>
<p>After each session I feel at peace with myself, very  energised and empowered.</p>
<p>Thank you ❤️</p>
<p>Maria Figueira</span>`
    },

    {
        quote: `<p>Karina is such a talented healer and teacher. I have been learning Permissioning with her and it has had such a positive impact on my life. Even after just a few days I experienced tangible results: I sleep better, I am generally more calm, more relaxed, I feel less anxious, and am more fundamentally centered. Permissioning is such an incredible tool because even after one session with Karina, you can do it on your own, in your home, on your own schedule. You can also adjust and personalize it, and you can take it to whatever depth or level you need. It is such a gift to have a tool like this that is easy, pairs wonderfully with a meditation practice, and is truly and palpably powerful. I am so endlessly grateful to Karina for sharing it.
        </p>`,
        citationName: `Kelin Nelson, Italy`
    },
    {
        quote: `<p>I have worked with many, many healers over the years, and Karina's permissioning work stands out for many reasons. First of all, she has an exceptional presence, even if it is just a voice on the phone. Her methods are straightforward and yet extremely deep. She engages you so that you are participatory in the work, and that involvement is fundamental for incorporating her techniques and approaches. She taught me her technique a few weeks ago and I have done it every single day since. This work is having a significant impact on my general health and well being. Healing modalities are highly personal, but I recommend Karina to everyone. If it is a fit, you'll know right away. I knew immediately.
        </p>`,
        citationName: `Deborah Barlow, US`
    },
    {
        quote: `<p>I recently had an accident in which the wheel of a car went over my right foot. Although there were no broken bones, the foot was intensely painful due to soft tissue damage and pulled ligaments and tendons. For the first few days it was impossible to put my foot to the ground. By the time I attended a physio appointment a week after the accident occurred I was able to put the outside of my heel to the ground and walk (hobble) with the help of crutches. The big toe joint was extremely painful inside the joint (I have a small bunion there), and the physio suggested there may have been a latent problem in the joint which had been triggered by the injury. She said it would be another 4-6 weeks before the injury healed and if a latent problem had been triggered it would need longer term care.</p>
        <p>Worried about longer term complications, it was at this point that I contacted Karina and had two sessions of QT. The first session was shared between the injury to the foot and another health problem I had. During the second session Karina worked only on the foot injury. At the end of the first session there was a significant drop in the pain levels in the foot, including the toe joint. After the second one, the pain had almost completely gone and, more importantly, the inside of the joint felt fine and I could walk pretty much normally again.</p>
        <p>Now, a week later, some residual minor ‘tenderness’ manifests with certain foot movements but apart from that I am mobile and can walk normally again, which is amazing.</p>
        <p>An unexpected and very welcome benefit of the sessions with Karina was that during the first session I felt the shock from the accident dissipate and disappear and I felt ‘back to normal’ again.</p>
        <p>Another unexpected benefit was how enjoyable the sessions were. The images which came to Karina during the treatment were amazingly accurate and fun to explore together.</p>
        <p>So thank you so much, Karina, for an amazing and delightful healing experience.</p>`,
        citationName: `CW. UK`
    },
    {
        quote: `<p>All my life I’ve had bad posture, and this has worsened from a few falls I had as an overactive kid which set my back off track and made me slightly wonky. When I went to see Karina it had reached a breaking point, where I would be in severe pain almost constantly and any sudden movement could handicap me for days. I couldn’t look to my left, tilt my head to the left and it hurt badly to lift my left arm. A friend of mine had researched Quantum-Touch healing and recommended I go see Karina. I saw no other way, as I was disinclined to go to a chiropractor who would crack and bend my very fragile back into place, although I was perhaps a bit skeptical about this new way of healing I had never heard of before.</p>
        <p>Karina was accommodating and booked me in as soon as she possibly could, and upon meeting her I immediately felt relaxed and welcomed; she is very warm and friendly and it is obvious she really enjoys helping people. She measured the difference of height of my shoulder blades to about 2cm. After one hour of work they had moved over 1cm closer to each other, and since that first session I haven’t been in pain. It feels incredible. I can now bend my head in every direction; sometimes I look to my left and feel really pleased about being able to!</p>
        <p>I have since been back for three more sessions, and they have all been intense and helpful. There is no doubt this is a legitimate and effective way of healing, and I heartily recommend anyone with similar (or other!) problems to see Karina, whether you are skeptical or not. I am also very much looking forward to attending her next workshop so that I, myself, can learn to maintain a healthy body and also help others.</p>`,
        citationName: `Kajsa Pålsson, Forest Hill / London Legal Secretary`
    },
    {
        quote: `<p>Testimonial received after a 5 minute Quantum-Touch taster session was given in front of an audience at Solar Events Quantum-Touch demonstration:</p>
        <p>I have to say a huge big thank you to you as the pain I was feeling in my lower back, left hip gloot and down my leg completely subsided a day later. You made 60% of the pain disappear within the 5 minute session that you did for me on that night (I can’t imagine what an hour would do for me!)</p>
        <p>It’s basically like you gave it a kick start &amp; boost for what would normally have taken a week to heal and I know this from other similar sporting injuries I have had in the past. So a very big thank you to you.</p>
        <p>This is something I definitely need to learn how to do properly, for my own injury healing and to help other fellow sporting people with their injuries.</p>`,
        citationName: `R. Dorn, London`
    },
    {
        quote: `<p>Since running the 2001 London Marathon, I’ve had two operations and exhausted virtually every avenue to relieve the pain of an ankle injury that has hampered me ever since. I had come to terms with the fact that the joint would never be 100 per cent again, and would have to live in pain. Some days were better than others. Then I tried Quantum-Touch.</p>
        <p>When I started the treatment, I had no idea what it was and what it involved, but had come to the stage that I was willing to give virtually anything a go.</p>
        <p>During my first session, I told Karina about my sporting background and how frustrated I was that the pain remained constant. She listened and then got to work. To my amazement, the joint improved just after one session. All of a sudden I had a pain-free spring in my step and a smile on my face.</p>
        <p>I returned on a weekly basis for 10 weeks and today am delighted to say that my ankle feels 98% better.</p>
        <p>The treatment itself is ultra-relaxing. Karina’s incredible powers get to work almost immediately. It’s as if you can feel magic circulating around your body. You just tell her where it hurts and she hits the spot within seconds.</p>
        <p>And when I came to her with a new ailment, she was straight on to it. Each session I came away from feeling energised and repaired.</p>`,
        citationName: `D.C – London Sports Journalist`
    },
    {
        quote: `<p><strong>Speechless.. Life Changing, Incredible!!</strong></p>
        <p>The week leading up to the first time I met Karina or even heard properly of Quantum-Touch, was undoubtedly the scariest and worst week of my life.</p>
        <p>It began with an unusual amount of clicking in my left knee, which developed quickly into a terrible burning pain. It then became completely stiff and restricted in movement. Within 4 days, the symptoms had worsened and spread to the entirety of both legs, hands, fingers, wrists and arms.</p>
        <p>I WAS BARELY ABLE TO WALK! so stiff, I couldn’t bend my legs.. in a situation where I felt I was very close to needing a walking stick. I remember getting on the tube, and restraining myself from crying the whole journey.. feeling something eating away at my legs, experiencing something truly awful happening. My legs so sensitive to touch that I feared every person walking past in case they accidentally knocked me. Every night when I barely slept, I wished so hard that I’d wake up the next morning back to normal and it was all just a bad dream. It didn’t happen.</p>
        <p>It was my mum who somehow stumbled across Quantum-Touch.. and Karina – MY HERO! She convinced me to give it a try.. I willingly agreed, even though I was unsure of what to expect, and a session was booked!</p>
        <p>All I can say is KARINA GRANT CAN GRANT YOUR WISHES! I’ll put it very simply.. I was barely able to walk up the stairs on the way up to her flat. After the 60 minute session… I WAS LITERALLY RUNNING DOWN THE STAIRS.. and as soon as I got home the music went on and I was dancing around like a child in a playground.</p>
        <p>Karina is so lovely, welcoming and compassionate. Each session spent with Karina feels like one spent with a friend. She is so positive and reassuring, just talking to her feels like a healing in itself. During the session many sensations were experienced. Intense heat, tingling in each knee.. and then later in the session I experienced what seemed like a bone move or rotate in each knee.</p>
        <p>Since that session I have also tried a long distance session with Karina, which is equally as powerful and completely astounding! This time it was for my hands and wrists.. which had been burning intensely for about 4 days straight. I remember being in the cinema and unable to concentrate on the film as it was so uncomfortable. As soon as the session began, the sensations began.. and I knew without mention which hand she was working on. Within 3 days after the session, the burning had vanished, like it had never existed. Two months on and it has never once returned.</p>
        <p>So I urge you and the people who you love and care about, who may need some healing, to please at least try one session with Karina! There is a little sceptical side there in some of us.. all I can say is think with an open heart and open mind. There is such beauty and infinite possibility in this world.</p>
        <p>Quantum-Touch is changing my life and the lives of those around me, each and every day!</p>`,
        citationName: `Joanna G, London - Style Director`
    },
    {
        quote: `<p>At the beginning of 2012, I gave up my exercise regime because I’d developed a problem with my pelvis. That was OK because I wanted more time anyway – to write my book and build my business.</p>
        <p>By the time November arrived – and after conventional medicine had failed miserably to remedy the problem, I realised that I was allowing the pain to dictate my life. I had a light bulb moment: I took responsibility for myself and arranged to see Karina.</p>
        <p>I’ve now had two Quantum-Touch sessions and have experienced amazing shifts in energy, understanding and pain in both of them. The pain level has moved from excruciating incapacity (8+ on a scale of 1 – 10) to minor niggles (&lt;2 on the same scale). My exercise regime is back on track and I’m now looking forward to a pain-free cycling holiday. Perhaps more importantly, I have a new, healthier relationship with my own body.</p>`,
        citationName: `Elaine Hopkins, redundancy activist and author`
    },
    {
        quote: `<p>I’ve always been intrigued by Quantum-Touch and came to Karina initially, for help with a back problem. From the very first session, my body started to release years (and I mean years) of tension.</p>
        <p>The more sessions I had the more I released and what was so amazing about QT was that I started to release issues I carried with me since childhood that I had almost forgot. Now, my back is the best it has been in years, and I feel lighter within myself. Karina is superb in what she does and she delivers what Quantum-Touch promises to deliver. She has compassion which comes through the work she does. I enjoy my sessions with Karina as you never know what comes up to be released and I would highly recommend Karina to anyone who is thinking about trying Quantum-Touch.</p>`,
        citationName: `TW, London`
    },
    {
        quote: `<p>I had pain in my left shoulder which I rated at an 8 before treatment and a 2 after the 15 minute demonstration. Very relaxing and enjoyable – thank you.</p>`,
        citationName: `Paul, Essex`
    },
    {
        quote: `<p>Having shattered my wrist in a number of places playing football, Karna’s healing process literally worked wonders. Within minutes I felt a gentle heat which felt nice (but to be honest I didn’t think much of) and this continued for over an hour. To my surprise, when I had an X-ray a few days later two of the bones that had previously moved had returned to their rightful place. I have the before and after X rays for whoever wants to see them. This was after only one session! Following my operation at The Garden Clinic I then had the benefit of another session and recovered 6 weeks ahead of schedule.</p>
        <p>Without hesitation, I would strongly recommend Karina’s services.</p>`,
        citationName: `D Toubian, London Commercial Property Adviser`
    },
    {
        quote: `<p>The email below was sent to me by the Mother of a 15 year old girl who came to me because she had misaligned shoulders which affected her ability to hold herself and dance, which is very important in her Performing Arts school. Please note that the names have been changed as requested.</p>
        <p>Hi Karina,<br />Belinda said that she feels that she can hold her shoulders back in a position that she has never been able to do before which is brilliant!</p>
        <p>I have noticed a difference in the way she is. She seems to be happier and full of life. She is really keen to exercise which is great for me as I have to go with her! It used to be a real struggle to get her out but now she really looks forward to it.</p>
        <p>Belinda struggles with relationships with people her own age for some reason. She really gets on with them and thinks of them as friends but for some reason people treat her quite badly, you know like leaving her out and stuff. She seems to be more confident now and definitely has a spring in her step and seems more positive. Also she suddenly has friends around her who are behaving like friends!</p>
        <p>Belinda absolutely loved you and she was amazed by what happened in her session.</p>
        <p>The following email was received 2 weeks after the session:<br />“Belinda’s shoulders are still back. In fact I tried to tell her to stand in a different way the other night and she said she couldn’t because, “This is the way I stand now”. Amazing!</p>
        <p>She said that she really believes in Quantum-Touch!”</p>
        <p>Thank you so much,<br />Love</p>`,
        citationName: `Cathy x`
    },
    {
        quote: `<p>Karina helped me to have a complete and full recovery from a broken arm within an amazingly short time frame, which seemed to have shocked the conventional doctors that I was going to see for a check up. I had a full recovery within five weeks and to this day I have trouble remembering which arm I actually broke!!! If only I had been in touch with Karina when I broke my leg some years back, perhaps I wouldn’t feel the stiffness that I sometimes feel from time to time from the pins that are now in my leg.</p>`,
        citationName: `Ross Aiken, London DJ and events promoter`
    },
    {
        quote: `<p>I sprained my wrist really badly a couple of weeks ago while attempting to wakeboard. I couldn’t open my hand properly and it hurt badly every time I tried to move it. The people at the water park bandaged my hand and suggested I see a doctor.</p>
        <p>Karina who was there on the day suggested that I try Quantum-Touch to relieve me of the pain and speed up the recovery time.</p>
        <p>I am not a great believer in alternative therapy but figured I may as well try it as was off on holiday the next day and would have tried anything to solve the problem.</p>
        <p>The treatment lasted about an hour and the only way I can describe it is that I felt a great deal of heat throughout the time Karina was treating me. She used only a very light touch and stopped a few times to ask me about the level of pain.</p>
        <p>For the first 20 minutes I could only feel the heat but afterwards my level of pain decreased gradually.</p>
        <p>By the end of the hour my hand still hurt but I could open and close it properly and my level of pain decreased from a 9 to a 5 and it didn’t rise again. I would have liked to continue the treatment but I went away the next day. The pain in my hand disappeared completely after a couple of days.</p>
        <p>I urge all skeptics, like me, to give it a go; ill certainly be going back to see her.</p>`,
        citationName: `J.L, London Marketing Manager`
    },
    {
        quote: `<p>Karina has helped me on numerous different occasions just with a sympathetic ear, what she does best is empower people!! You really believe you can achieve what you want to, or put right whatever went wrong after a healing session with Karina.</p>`,
        citationName: `Anon London`
    },
    {
        quote: `<p>When I was first introduced to QT by Karina I found it to extremely powerful, I have previously had physiotherapy and tried all sorts to help with golfers elbow and none of the techniques used where anywhere near as good as QT in relieving the pain.</p>
        <p>As soon as Karina started to work on my pain I could literally feel the energy flowing within minutes, the pain would move around (the area she was working on) and she would use techniques to follow the pain and release it. The process involved was very comfortable for me – I was fully clothed, there was no manipulation whatsoever and it just seemed to work at an amazing speed!</p>
        <p>Whenever I have had QT treatments I have always walked away feeling great, I had to have a couple of sessions for the nagging aches in my knees and after the 3rd session the pain has almost completely gone.</p>
        <p>I would highly recommend this to anyone who is in pain, it is always my first choice of treatment and QT just seems to work wonders!</p>`,
        citationName: `Steven D, London Programmer`
    },
    {
        quote: `<p>I came to see Karina with Arthritis problems in my hands. The pain was extremely bad when I got to her and when she asked me to rate it I definitely gave it a 10.<br />She gave me Quantum-Touch for an hour and a half and during this time I felt the pain move around and slowly decrease down to a 2. My hands started to feel much more supple and I was able to move my fingers much more freely again at the end of the session. I continued to have weekly sessions for several weeks during the cold weather and I can honestly say that QT has been a savior to me in dealing with my Arthritis.</p>`,
        citationName: `Diana S, London`
    },
    {
        quote: `<p>Thank you so much. You are a natural. Yesterday everyone said I looked better. Slept well for first time in ages. Wish every day was like that. a thousand thank you’s.</p>`,
        citationName: `G Montes London`
    },
    {
        quote: `<p>Karina reduced my nagging shoulder pain from a 7/10 to 0 within 30 minutes. Needless to say I was extremely happy and very impressed!</p>`,
        citationName: `P. Bessingham London`
    },
    {
        quote: `<p>Quantum-Touch energy healing has healed me more than I had believed it could.</p>
        <p>I had very low energy and low moods, IBS (developed 5 years ago), Acne Rosacea (I was on antibiotics almost a year) and constant backaches and headaches.</p>
        <p>I also had had an arthroscopy for a torn meniscus on my left knee 2 years earlier and suffered daily with a stiff achy knee since.<br />After my first healing session my digestive discomfort vastly improved. Over the next few sessions Karina worked on my back and knee to great effect. I have not needed to visit my osteopath once since. Karina worked on my Liver to improve energy and cleared my Acne Rosacea completely. My quality of life has improved tremendously. I am more energetic and aware, and my moods are less intense. A pleasant surprise was the effect it had on my hair, which grew noticeably thicker and fuller.</p>
        <p>I highly recommend it!</p>`,
        citationName: `F.W, London`
    },
    {
        quote: `<p>A few months ago I was visited by a lady who I will call Mary. She explained that she had terrible bouts of pain and was due to have a section of her bowel removed and stitched back together in surgery. The doctors had advised that this was the only way to put a stop to the drugs she would have to continually take otherwise. Mary only had the money for one session and although she didn’t expect any type of miracle after just one hour, she was certainly open to it.</p>
        <p>We had our session and she told me she felt so peaceful and calm, more so than she had felt in a long time. She later emailed me to say that she had experienced another flare up yet still felt that same peace. She had more tests and was told she would almost certainly need the surgery. However, she looked forward to more sessions in the future and coming on a workshop when able to do so.</p>
        <p>I never heard from Mary again after that until approximately 6 months later when I was delighted to receive the following email: “Just to let you know – I never had the surgery and seem to be symptom free! Unbelievable – thank you!”</p>
        <p>Some time after that Mary reiterated her healing to me, saying “Even though I have been through untold stress I have not needed the surgery and am not in pain.”</p>
        <p>This healing story is a reminder to stay open to miracle results and know that sometimes the healing can take a little while to assimilate before the results show up. I have known results take 2 weeks to show up after a session and when they do, the are usually incredibly profound. Rather than focus on what you see physically in front of you, always keep your focus on what you want to see and make sure you are adding energy to that.</p>
        <p>Mary’s story is a perfect example of how sometimes symptoms can worsen before they completely disappear and staying open for miracles regardless.</p>
        <p>Quantum-Touch is truly a healing pathway to facilitate small and big miracles!</p>`,
        citationName: `Anon`
    },
    {
        quote: `<p>Hi Karina</p>
        <p>I wanted to let you know how wonderful it was meeting you at the Photoreading weekend workshop in London. The introduction you gave us to Quantum-Touch was a powerful moment for me providing confirmation again that God and the Universe does not want us to suffer a moment longer than necessary. I was so impressed with the healings you conducted in front of my very eyes, both involving bone structure shifts and realignment to enable to participants of my group to gain immediate benefits and increased mobility.</p>
        <p>It was fantastic to see so little effort needed to achieve this. I thank you from the bottom of my heart for the few minutes you spent with me and I do think i gained in raising my vibrations as I have not since had a occurrence of the particular concerns. I am so grateful – thank you.</p>
        <p>I look forward to seeing you at the July Workshop and in keeping in my you most humble statement that anyone could do what you are doing , with a view to becoming a practitioner in the near future. I feel blessed to have met you and been introduced to this form of healing.</p>
        <p>Thanks</p>`,
        citationName: `Val.Groves London`
    },
    {
        quote: `<p>For about the last five years, I have felt severe restrictions in my left hip joint.As a result, I limped, and often experienced pain, sometimes so intense I could not walk another step.</p >
<p>Trained as a nurse as well as in different somatic modalities, I did what I could to help myself. Occasionally I used Quantum-Touch on myself, and found it usually worked better and faster than conventional pain killers. But - busy in my nurse's life, I often "forgot" this easy modality to look after myself.</p>
<p>Around Christmas time in 2020, I joined in an online meditation class, and felt intense relief in my hip afterward. This experience repeated itself with online QT group sessions I participated in, hosted by Karina. My hip felt better and better, walking became easier, and I was often pain-free. But it was not until I finally received the official diagnosis of "advanced hip osteoarthritis" that I committed to running energy to myself on a daily basis.</p>
<p>Additionally, I booked an online session with Karina as an extra boost. And boost it did! During the session I felt a strong energy lift my bodily structures from the inside.</p>
<p>Afterwards, I was standing and walking much straighter. My limp has mostly vanished except for a residue owed to the immobility of the hip joint, due to the joint destruction.I noticed my muscles recover much faster now after efforts such as long walking and lifting loads. Also, immediately following the session, I noticed "coincidences" such as Youtube proposing me just the perfect beneficial exercise videos, and helpful comments and advice by fellow humans with the same challenges. Last week I received my MRI results, confirming a stage 4 advanced osteoarthritis with femoral head deformation etc etc....It sounds grotesque considering how good I feel and move. I do intend to wait as long as possible before getting hip replacement.</p>`,
        citationName: `Lot's of love - Oriane`
    },
    {
        quote: `<p>My husband had been suffering from long Covid for about 6 months before the wonderful Karina was able to change his life around. His symptoms included: brain fog, breathlessness, fatigue and dizziness. Having been an active person, who went to the gym twice a week he was unable to even go for a short walk.</p>
        
        <p>Our lifestyle had changed dramatically and all the things we did together as a family like cycling, spending days out etc. could no longer happen. After a few months it affected his positive nature and he began to feel low and depressed. </p>
        
        <p>After simply one session with Karina it all changed. Karina guided him through the healing process and about half way through he felt a huge emotional release and felt tears running down his cheeks. These were neither happy or sad, they just were.</p>
        
        <p>Soon after this session we were heading overseas to Greece for a wedding. We were very apprehensive as to how the tiring experience of catching an international flight was going to be. All I can say is that it was as if he had never been ill. During the course of the summer holidays he got stronger and stronger, and was quickly back to his normal self, able to do all those physically active things again.</[>]

        <p>I am so grateful to Karina and in awe of the process of Quantum Touch. Thank you!</p>
        `,
        citationName: `Anne W, UK`
    },
    {
        quote: `<p>I wanted to let you know that I feel you may have healed my herniated disc that has been bothering me for the last 12 years. I still have occasional tightness around my back muscles (which I need to strengthen) but it is not the distinct herniated pain. </p>
        
        <p>Frankly, I just couldn't believe it and kept waiting for the pain to come back but it hasn't. Secondly, while the pain in my shoulder and thumb tingling has been more stubborn…I almost cannot explain it... my thumb used to feel really stiff and contracted and after the session, it has completely opened up. </p>
        
        <p>I no longer have any pain in my arm either. If you remember, during the session, my right hand was twisting like a mop cloth! Thirdly, this one is also difficult to explain but I had cried so very deeply during the session and I didn't know why I was crying. I feel now that the grief and trauma related to my mom was stuck in my body and has been released. Of course I mourn and miss mom but I don't burst into tears ever time I think about her... I can think of her and still be at peace.

        Karina, I want to thank you deeply with all my heart. The universe conspired for me to find your website out of the blue. Thank you so very much 🙏.</p>`,
        citationName: `R.O, USA`
    },
    {
        quote: `<p>Karina has been a healer and guide to me personally as well as a teacher I've brought into companies I've worked with to keep our team operating from high levels of energy. Her work has been nothing short of life changing, with her intuitive powers in particular helping me get insights into my life I'd never seen before. A blindingly bright soul, I'd recommend her to any individual or team looking to heal and perform at their best.</p>`,
        citationName: `Ian Randolph Head of Product and R&D at Trudy and Tailify`
    },
]
export default fixture